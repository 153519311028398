import axios from 'axios'
let baseURL = ''
if (process.env.NODE_ENV === 'development') {
	// 开发环境
	baseURL = ''
} else if (process.env.NODE_ENV === 'staging') {
	// 生产环境
	baseURL = ''
} else {
	// 生产环境
	baseURL = ''
}
const service = axios.create({
	// 自定义的请求头
	headers: {
		'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
	},
	// 最多重发三次
	retry: { retries: 3 },
	// 超时设置
	timeout: 600000,
	// 是否是可信任(表示跨域请求时是否需要使用凭证)
	withCredentials: true,
	crossDomain: true,
	debug: process.env.NODE_ENV !== 'production'
	// // 响应的数据格式 json / blob /document /arraybuffer / text / stream
	// responseType: 'json',
	// XSRF 设置
	// xsrfCookieName: 'XSRF-TOKEN',
	// xsrfHeaderName: 'X-XSRF-TOKEN'
})
// POST 传参序列化
service.interceptors.request.use(
	config => {
		config.baseURL = baseURL
		config.headers['Content-Type'] = 'application/json;charset=UTF-8'
		if (localStorage.token) config.headers['token'] = localStorage.token
		return config
	},
	error => {
		return Promise.reject(error)
	}
)
// 返回状态判断
service.interceptors.response.use(
	res => {
		// 返回这里，根据你自己的情况进行调整
		return res.data
	},
	error => {
		return Promise.reject(error)
	}

)
export const baseUrl = baseURL
export function request (url, params_data, method) {
	let new_params_data = { ...params_data, store_id: localStorage.store_id || 165 }
	return service({
		url: baseURL + url,
		method: method || 'post',
		[method == 'get' ? 'params' : 'data']: new_params_data
	})
}


