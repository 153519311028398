<template>
	<router-view />
</template>
<script>
export default {
	data() {
		return {
			version: 1.3,
		};
	},
	created() {
		if (this.version != localStorage.version) {
			localStorage.clear();
			localStorage.version = this.version;
			window.location.reload(true);
		}
		var ua = navigator.userAgent;
		var ipad = ua.match(/(iPad).*OS\s([\d_]+)/),
			isIphone = !ipad && ua.match(/(iPhone\sOS)\s([\d_]+)/),
			isAndroid = ua.match(/(Android)\s+([\d.]+)/),
			isMobile = isIphone || isAndroid;
		//判断
		if (isMobile) {
			localStorage.is_pc = 0;
			console.log('移动端');
			var ua = window.navigator.userAgent.toLowerCase();
			if (ua.match(/MicroMessenger/i) == 'micromessenger') {
				localStorage.is_weixin = 1;
				console.log('微信移动端');
			} else {
				localStorage.is_weixin = 0;
				console.log('安卓ios移动端');
			}
		} else {
			localStorage.is_pc = 1;
			console.log('pc端');
		}
		var vars = {};
		window.location.href.replace(/[?&]+([^=&]+)=([^&#]*)/gi, function (m, key, value) {
			vars[key] = value;
		});
		if (vars.store_id) localStorage.store_id = vars.store_id;
		if (vars.token) {
			localStorage.token = vars.token;
			// this.$message({
			// 	message: '登录成功',
			// 	type: 'success',
			// });
		} else {
			// localStorage.clear();
			// window.location.href = `https://login-plat.xiebanyun.com/?url=${window.location.href + '?plat=form'}`;
		}
		window.addEventListener('pageshow', () => {
			//这里写要触发的代码
			this.$message({
				customClass: 'init_message',
				message: '加载成功',
				type: 'success',
				duration: 2000,
			});
		});
		window.addEventListener('message', function (event) {
			if (event.origin === 'https://supply.saas.xiebanyun.com') {
				const res = event.data;
				if (res.type === 'removeLocalStorage') {
					localStorage.removeItem(res.key);
				}
			}
		});
	},
	methods: {},
};
</script>
<style>
.init_message {
	left: 5000px !important;
}
body {
	margin: 0;
}
* {
	box-sizing: border-box;
	padding: 0;
	margin: 0;
}
</style>
