import { createRouter, createWebHistory, createWebHashHistory } from 'vue-router'

const routes = [
	{
		path: '/',
		redirect: '/journal'
	},
	{
		path: '/journal',
		name: 'journal',
		component: () => import('../views/Book/BookList.vue')
	},
	{
		path: '/journalPreview',
		name: 'journalPreview',
		component: () => import('../views/Book/BookLook.vue')
	},
	{
		path: '/file',
		name: 'file',
		component: () => import('../views/FileDownload/FileList.vue')
	},



]
const router = createRouter({
	history: createWebHashHistory(),
	routes
})

export default router
